import { createMemo, splitProps, Show, createSignal, createEffect } from 'solid-js';
import type { Component, JSX } from 'solid-js';
import IconFilledCheck from '~/assets/images/property/filledCheck.svg';
import { cn } from '~/utils/classnames';
import { uuid } from '~/utils/tools';

type CheckboxProps = Omit<JSX.InputHTMLAttributes<HTMLInputElement>, 'onInput'> & {
  class?: string;
  id?: string;
  label?: JSX.Element;
  onInput?: (checked: boolean) => void;
  showLabel?: boolean;
  checkBoxClass?: string;
  htmlType?: 'radio' | 'checkbox';
  labelPosition?: 'left' | 'right';
  checkedValue?: boolean;
  labelClass?: string;
};

export const Checkbox: Component<CheckboxProps> = (props) => {
  const [checked, setChecked] = createSignal(false);

  createEffect(() => {
    setChecked(props.checkedValue || false);
  });

  const id = createMemo(() => props.id || uuid());
  const [localProps, inputProps] = splitProps(props, ['id', 'label', 'class', 'htmlType', 'labelPosition']);

  return (
    <div
      class={cn(
        'inline-flex select-none items-center gap-2',
        props.checkBoxClass,
        localProps.labelPosition === 'right' && 'flex-row-reverse'
      )}>
      <Show when={localProps.labelPosition === 'left'}>
        <Show when={props.showLabel && props.label}>
          <label
            for={id()}
            class={cn('cursor-pointer capitalize', localProps.labelPosition === 'left' && 'order-1 ml-0 mr-4', props.labelClass)}>
            {props.label}
          </label>
        </Show>
      </Show>
      <label class="relative flex items-center " for={id()} data-ripple-dark="true">
        <input
          type={localProps.htmlType || 'checkbox'}
          class={cn(
            "before:content[''] border-blue-gray-200 before:bg-blue-gray-500 checked:border-essential-colour checked:bg-essential-colour peer relative size-5 cursor-pointer appearance-none rounded-md border transition-all before:absolute before:left-2/4 before:top-2/4 before:block before:size-12 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full before:opacity-0 before:transition-opacity hover:before:opacity-10",
            props.class,
            checked() && 'bg-primary-color'
          )}
          id={id()}
          {...inputProps}
          checked={checked()}
          onInput={(ev) => {
            setChecked(ev.target.checked);
            props?.onInput?.(ev.target.checked);
          }}
        />
        <div
          class={cn(
            'pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-white opacity-0 transition-opacity',
            checked() && 'opacity-100'
          )}>
          <img src={IconFilledCheck} alt="" />
        </div>
      </label>
      <Show when={localProps.labelPosition !== 'left'}>
        <Show when={props.showLabel && props.label}>
          <label for={id()} class={cn('cursor-pointer capitalize', localProps.labelPosition === 'left' && 'order-1 ml-0 mr-4')}>
            {props.label}
          </label>
        </Show>
      </Show>
    </div>
  );
};
